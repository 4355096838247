const createFedopsLogger = fedopsLoggerFactory => {
  const wixCodeAppDefId = '675bbcef-18d8-41f5-800e-131ec9e08762';
  const wixCodeAppName = 'wix-code-viewer-app';

  return fedopsLoggerFactory.getLoggerForWidget({
    appId: wixCodeAppDefId,
    appName: wixCodeAppName,
  });
};

module.exports = { createFedopsLogger };
